import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { InboundFax } from '../../../core/models/inbound-fax.model';
import { Router } from '@angular/router';
import { InboundFaxesService } from '../../../core/services/inbound-faxes.service';
import { Patient } from '../../../core/models/patient.model';
import { Prescriber } from '../../../core/models/prescriber.model';
import { AppToastService } from 'app/core/services/app-toast.service';
import { InboundFaxOutcome } from '../inbound-fax-select-outcome/inbound-fax-select-outcome.component';
import { InboundFaxStatus } from '../../../core/enums/inbound-fax-status.enum';
import { InboundFaxStatusReason } from '../../../core/enums/inbound-fax-status-reason.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { OutboundFax } from '../../../core/models/outbound-fax.model';
import { InboundFaxCommunicationMethod } from 'app/core/enums/inbound-fax-communication-method.enum';

@Component({
  selector: 'app-inbound-fax-process-form',
  templateUrl: './inbound-fax-process-form.component.html',
  styleUrls: ['./inbound-fax-process-form.component.scss'],
})
export class InboundFaxProcessFormComponent implements OnInit {
  serverError = false;
  validationErrors = new Array<string>();

  @Input() inboundFax: InboundFax;
  @ViewChild('selectPatient', { static: true })
  selectPatient;
  junkNonMedicalDocumentSelected = false;
  showMultiplePatientsWarning = false;

  private source = '';

  constructor(
    private inboundFaxesService: InboundFaxesService,
    private router: Router,
    private toastService: AppToastService,
  ) {}

  ngOnInit() {
    if (this.router.url.match('my-work')) {
      this.source = 'my';
    }

    this.setJunkNonMedicalDocumentSelected();
    this.ensureValidCommunicationMethod();
    this.setShowMultiplePatientsWarning();
  }

  onPatientSelectionChange(patient: Patient): void {
    this.inboundFax.sourcePatient = patient;

    if (patient && patient.mainPatient) {
      this.inboundFax.patient = patient.mainPatient;
    } else if (patient) {
      this.inboundFax.patient = patient;
    } else {
      this.inboundFax.patient = null;
    }

    this.inboundFax.prescriber = null;
  }

  onPrescriberSelectionChange(prescriber: Prescriber): void {
    this.inboundFax.prescriber = prescriber;
    this.inboundFax.provider = prescriber?.provider;
  }

  onOutboundFaxChange(outboundFax: OutboundFax): void {
    this.inboundFax.outboundFax = outboundFax;
    this.inboundFax.communicationMethod = null;
    this.ensureValidCommunicationMethod();
  }

  onFaxOutcomeChange(outcome: InboundFaxOutcome): void {
    this.inboundFax.status = outcome.status;
    this.inboundFax.notes = outcome.notes;

    if (this.inboundFax.status === InboundFaxStatus.notValidEvidence) {
      this.inboundFax.statusReason = outcome.statusReason;
    } else {
      this.inboundFax.statusReason = null;
    }

    if (this.inboundFax.status === InboundFaxStatus.needsManualIntervention) {
      this.inboundFax.patient = null;
      this.inboundFax.prescriber = null;
      this.selectPatient.resetSearchForm();
    }

    this.setJunkNonMedicalDocumentSelected();
    this.ensureValidCommunicationMethod();
  }

  onCommunicationMethodChange(communicationMethod: string): void {
    this.inboundFax.communicationMethod = communicationMethod;
  }

  onMultiplePatientFeedbackChange(valid: boolean): void {
    this.inboundFax.llmPatientsResultValid = valid;
  }

  onSaveClick() {
    this.serverError = false;

    if (this.validate()) {
      this.inboundFaxesService.transition(this.inboundFax).subscribe(
        () => {
          this.goToNextFax();
        },
        (err: HttpErrorResponse) => {
          this.serverError = true;
          console.error(err);
        }
      );
    }
  }

  onCancelClick() {
    this.navigateToQueue();
  }

  onNextClick() {
    this.skipToNextFax();
  }

  public get selectedCommunicationMethod() {
    return this.inboundFax.communicationMethod;
  }

  private skipToNextFax() {
    this.inboundFaxesService.skipToNextFax(this.source).subscribe(({ item }) => {
      if (item) {
        this.inboundFax = item;
        this.router.navigate([`${this.navigationPrefix()}/${this.inboundFax.id}`]);
      }
    });
  }

  private goToNextFax() {
    this.inboundFaxesService.getNextFax(this.source).subscribe(({ item }) => {
      if (item) {
        this.inboundFax = item;
        this.showConfirmation('Success! Fax was saved. Now showing the next fax in the queue.');
        this.router.navigate([`${this.navigationPrefix()}/${this.inboundFax.id}`]);
      } else {
        this.showConfirmation('Success! Fax was saved. No more faxes in the queue.');
        this.navigateToQueue();
      }
    });
  }

  private navigateToQueue() {
    this.router.navigate([this.navigationPrefix()]);
  }

  private navigationPrefix() {
    if (this.source === 'my') {
      return '/capture-admin/my-work/faxes';
    } else {
      return '/capture-admin/communications/inbound-faxes';
    }
  }

  private showConfirmation(message: string) {
    this.toastService.show(message, {
      cssClass: 'bg-success-subtle',
    });
  }

  private validate(): boolean {
    this.validationErrors = new Array<string>();

    if (this.inboundFax.status === InboundFaxStatus.validEvidence ||
        this.inboundFax.status === InboundFaxStatus.notValidEvidence) {
      if (this.patientAndPrescriberRequired() && !this.inboundFax.patient) {
        this.validationErrors.push('Patient is required.');
      }

      if (this.patientAndPrescriberRequired() && !this.inboundFax.prescriber) {
        this.validationErrors.push('Prescriber is required.');
      }

      if (this.reasonRequired() && !this.inboundFax.statusReason) {
        this.validationErrors.push('Reason is required.');
      }

      if (this.notesRequired() && !(this.inboundFax.notes && this.inboundFax.notes.length > 0)) {
        this.validationErrors.push('Additional details are required.');
      }

      if (this.outboundFaxRequired() && !this.inboundFax.outboundFax) {
        this.validationErrors.push('Outbound fax is required.');
      }

      if (this.showMultiplePatientsWarning && this.inboundFax.llmPatientsResultValid === undefined) {
        this.validationErrors.push('Please indicate multiple patient validity.');
      }
    }

    return this.validationErrors.length === 0;
  }

  private setJunkNonMedicalDocumentSelected() {
    this.junkNonMedicalDocumentSelected =
      this.inboundFax.status === InboundFaxStatus.notValidEvidence &&
      this.inboundFax.statusReason === InboundFaxStatusReason.junkNonMedicalDocument;
  }

  private ensureValidCommunicationMethod() {
    if (this.junkNonMedicalDocumentSelected) {
      this.inboundFax.communicationMethod = null;
    } else {
      this.inboundFax.communicationMethod =
        this.inboundFax.communicationMethod ||
        this.inboundFax.outboundFax?.office?.communicationMethod ||
        InboundFaxCommunicationMethod.standardFax;
    }
  }

  private reasonRequired() {
    return this.inboundFax.status === InboundFaxStatus.notValidEvidence;
  }

  private notesRequired() {
    return this.inboundFax.status === InboundFaxStatus.notValidEvidence &&
      this.inboundFax.statusReason === InboundFaxStatusReason.other;
  }

  private patientAndPrescriberRequired() {
    return this.inboundFax.statusReason !== InboundFaxStatusReason.junkNonMedicalDocument;
  }

  private outboundFaxRequired() {
    return this.inboundFax.statusReason !== InboundFaxStatusReason.junkNonMedicalDocument;
  }

  private setShowMultiplePatientsWarning() {
    if (this.inboundFax.latestLlmResultId && this.inboundFax.possiblePatientInfo.length > 1) {
      this.showMultiplePatientsWarning = true;
    }
  }
}
