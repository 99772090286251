import { NgModule, inject } from '@angular/core';
import { environment } from '../environments/environment';
import { Routes, RouterModule, PreloadAllModules, ActivatedRouteSnapshot } from '@angular/router';
import { NotFoundComponent } from './shared/pages/not-found/not-found.component';
import { MaintenanceComponent } from './shared/pages/maintenance/maintenance.component';

import { AuthGuard } from './auth/guards/auth.guard';
import { AccessManageUsersGuard } from './auth/guards/access-manage-users.guard';
import { AccessManageClientsGuard } from './auth/guards/access-manage-clients.guard';
import { AccessManageClientChoresGuard } from './auth/guards/access-manage-client-chores.guard';
import { AccessReferralClaimsGuard } from './auth/guards/access-referral-claims.guard';
import { AccessIccClaimsGuard } from './auth/guards/access-icc-claims.guard';
import { DeactivatedClientCePortalTasksGuard } from './auth/guards/deactivated-client-ce-portal-tasks.guard';

import { CaptureDetailComponent } from './captures/pages/capture-detail/capture-detail.component';
import { CaptureHistoryComponent } from './captures/pages/capture-history/capture-history.component';
import { CaptureHistoryNewComponent } from './captures/pages/capture-history-new/capture-history-new.component';

import { CaptureAdminComponent } from './capture-admin/capture-admin.component';
import { CaptureAdminInboundFaxesComponent } from './capture-admin/pages/capture-admin-inbound-faxes/capture-admin-inbound-faxes.component';
import { CaptureAdminInboundFaxComponent } from './capture-admin/pages/capture-admin-inbound-fax/capture-admin-inbound-fax.component';
import { CaptureAdminFaxUploadComponent } from './capture-admin/pages/capture-admin-fax-upload/capture-admin-fax-upload.component';
import { CaptureAdminCaptureReportsComponent } from './capture-admin/pages/capture-admin-capture-reports/capture-admin-capture-reports.component';
import { CaptureAdminUserTimingReportsComponent } from './capture-admin/pages/capture-admin-user-timing-reports/capture-admin-user-timing-reports.component';
import { CaptureAdminMissingNdcReportsComponent } from './capture-admin/pages/capture-admin-missing-ndc-reports/capture-admin-missing-ndc-reports.component';
import { CaptureAdminFaxablePatientPrescriberReportsComponent } from './capture-admin/pages/capture-admin-faxable-patient-prescriber-reports/capture-admin-faxable-patient-prescriber-reports.component';
import { CaptureAdminSpecialtyStoreClaimPatientMatchReportsComponent } from './capture-admin/pages/capture-admin-specialty-store-claim-patient-match-reports/capture-admin-specialty-store-claim-patient-match-reports.component';
import { CaptureAdminClientsComponent } from './capture-admin/pages/capture-admin-clients/capture-admin-clients.component';
import { CaptureAdminClientComponent } from './capture-admin/pages/capture-admin-client/capture-admin-client.component';
import { CaptureAdminAuditComponent } from './capture-admin/pages/capture-admin-audit/capture-admin-audit.component';
import { CaptureAdminClientOptionsAuditComponent } from './capture-admin/pages/capture-admin-client-options-audit/capture-admin-client-options-audit.component';
import { CaptureAdminClientAuditComponent } from './capture-admin/pages/capture-admin-client-audit/capture-admin-client-audit.component';
import { CaptureAdminClientConsultNotesComponent } from './capture-admin/pages/capture-admin-client-consult-notes/capture-admin-client-consult-notes.component';
import { CaptureAdminPartnerPortalClaimReportComponent } from './capture-admin/pages/capture-admin-partner-portal-claim-report/capture-admin-partner-portal-claim-report.component';
import { CaptureAdminImportReferralPrescriberSpecialtyMappingComponent } from './capture-admin/pages/capture-admin-import-referral-prescriber-specialty-mapping/capture-admin-import-referral-prescriber-specialty-mapping.component';
import { CaptureAdminImportReferralPrescriberSpecialtyMappingHistoryComponent } from './capture-admin/pages/capture-admin-import-referral-prescriber-specialty-mappings-history/capture-admin-import-referral-prescriber-specialty-mappings-history.component';

import { ClientPortalComponent } from './client-portal/client-portal.component';
import { ClientPortalSearchComponent } from './client-portal/pages/client-portal-search/client-portal-search.component';
import { ClientPortalMatchReportsComponent } from './client-portal/pages/client-portal-match-reports/client-portal-match-reports.component';
import { ClientPortalConsultNotesComponent } from './client-portal/pages/client-portal-consult-notes/client-portal-consult-notes.component';
import { ClientPortalPreferencesComponent } from './client-portal/pages/client-portal-preferences/client-portal-preferences.component';
import { ClientPortalClientPrescriberTasksComponent } from './client-portal/pages/client-portal-client-prescriber-tasks/client-portal-client-prescriber-tasks.component';
import { ClientPortalDirectConsultNotesComponent } from './client-portal/pages/client-portal-direct-consult-notes/client-portal-direct-consult-notes.component';
import { ClientPortalPatientInteractionLocationsComponent } from './client-portal/pages/client-portal-patient-interaction-locations/client-portal-patient-interaction-locations.component';

import { PartnerPortalComponent } from './partner-portal/partner-portal.component';
import { PartnerPortalSearchComponent } from './partner-portal/pages/partner-portal-search/partner-portal-search.component';
import { PartnerPortalMatchReportsComponent } from './partner-portal/pages/partner-portal-match-reports/partner-portal-match-reports.component';
import { CaptureAdminCaptureBillingStateReportsComponent } from './capture-admin/pages/capture-admin-capture-billing-state-reports/capture-admin-capture-billing-state-reports.component';
import { CaptureAdminCaptureInvoiceLineItemReportsComponent } from './capture-admin/pages/capture-admin-capture-invoice-line-item-reports/capture-admin-capture-invoice-line-item-reports.component';
import { ClientPrescriberTaskComponent } from './client-tasks/pages/client-prescriber-task/client-prescriber-task.component';
import { CaptureAdminClientPrescriberTasksComponent } from './capture-admin/pages/capture-admin-client-prescriber-tasks/capture-admin-client-prescriber-tasks.component';
import { CaptureAdminFileCheckResultsComponent } from './capture-admin/pages/capture-admin-file-check-results/capture-admin-file-check-results.component';
import { CaptureAdminFileCheckComponent } from './capture-admin/pages/capture-admin-file-check/capture-admin-file-check.component';
import { CaptureAdminClientFilesComponent } from './capture-admin/pages/capture-admin-client-files/capture-admin-client-files.component';
import { CaptureAdminPartnerFilesComponent } from './capture-admin/pages/capture-admin-partner-files/capture-admin-partner-files.component';
import { CaptureAdminUsersComponent } from './capture-admin/pages/capture-admin-users/capture-admin-users.component';
import { CaptureAdminUserComponent } from './capture-admin/pages/capture-admin-user/capture-admin-user.component';
import { CaptureAdminUserNewComponent } from './capture-admin/pages/capture-admin-user-new/capture-admin-user-new.component';
import { CaptureAdminUserEditComponent } from './capture-admin/pages/capture-admin-user-edit/capture-admin-user-edit.component';
import { CaptureAdminUserAuditComponent } from './capture-admin/pages/capture-admin-user-audit/capture-admin-user-audit.component';
import { ClientPortalPrescriptionReportComponent } from './client-portal/pages/client-portal-prescription-report/client-portal-prescription-report.component';
import { ClientPortalCaptureInvoiceLineItemReportComponent } from './client-portal/pages/client-portal-capture-invoice-line-item-report/client-portal-capture-invoice-line-item-report.component';
import { ClientPortalClientConsultNoteTasksComponent } from './client-portal/pages/client-portal-client-consult-note-tasks/client-portal-client-consult-note-tasks.component';
import { ClientConsultNoteTaskComponent } from './client-tasks/pages/client-consult-note-task/client-consult-note-task.component';
import { CaptureAdminImportOutboundFaxesComponent } from './capture-admin/pages/capture-admin-import-outbound-faxes/capture-admin-import-outbound-faxes.component';
import { CaptureAdminImportOutboundFaxesHistoryComponent } from './capture-admin/pages/capture-admin-import-outbound-faxes-history/capture-admin-import-outbound-faxes-history.component';
import { CaptureAdminImportClaimsComponent } from './capture-admin/pages/capture-admin-import-claims/capture-admin-import-claims.component';
import { CaptureAdminImportClaimsHistoryComponent } from './capture-admin/pages/capture-admin-import-claims-history/capture-admin-import-claims-history.component';
import { CaptureAdminClientNewComponent } from './capture-admin/pages/capture-admin-client-new/capture-admin-client-new.component';
import { CaptureAdminClientEditComponent } from './capture-admin/pages/capture-admin-client-edit/capture-admin-client-edit.component';
import { CaptureAdminClientEditConfigurationComponent } from './capture-admin/pages/capture-admin-client-edit-configuration/capture-admin-client-edit-configuration.component';
import { CaptureAdminClientConsultNoteTasksComponent } from './capture-admin/pages/capture-admin-client-consult-note-tasks/capture-admin-client-consult-note-tasks.component';
import { HomeComponent } from './shared/pages/home/home.component';
import { CaptureAdminImportCaptureInvoiceLineItemsComponent } from './capture-admin/pages/capture-admin-import-capture-invoice-line-items/capture-admin-import-capture-invoice-line-items.component';
import { CaptureAdminImportCaptureInvoiceLineItemsHistoryComponent } from './capture-admin/pages/capture-admin-import-capture-invoice-line-items-history/capture-admin-import-capture-invoice-line-items-history.component';
import { CaptureAdminImportPatientInteractionLocationsComponent } from './capture-admin/pages/capture-admin-import-patient-interaction-locations/capture-admin-import-patient-interaction-locations.component';
import { CaptureAdminImportPatientInteractionLocationsHistoryComponent } from './capture-admin/pages/capture-admin-import-patient-interaction-locations-history/capture-admin-import-patient-interaction-locations-history.component';
import { CaptureAdminCaptureQueueComponent } from './capture-admin/pages/capture-admin-capture-queue/capture-admin-capture-queue.component';
import { CaptureFaxComponent } from './captures/pages/capture-fax/capture-fax.component';
import { CaptureAdminClientDirectConsultNotesComponent } from './capture-admin/pages/capture-admin-client-direct-consult-notes/capture-admin-client-direct-consult-notes.component';
import { ClientPortalClientReferralTasksComponent } from './client-portal/pages/client-portal-client-referral-tasks/client-portal-client-referral-tasks.component';
import { ClientReferralTaskComponent } from './client-tasks/pages/client-referral-task/client-referral-task.component';
import { CaptureAdminClientReferralTasksComponent } from './capture-admin/pages/capture-admin-client-referral-tasks/capture-admin-client-referral-tasks.component';
import { CaptureAdminInboundCallsComponent } from './capture-admin/pages/capture-admin-inbound-calls/capture-admin-inbound-calls.component';
import { CaptureAdminInboundCallComponent } from './capture-admin/pages/capture-admin-inbound-call/capture-admin-inbound-call.component';
import { CaptureAdminInboundCallNewComponent } from './capture-admin/pages/capture-admin-inbound-call-new/capture-admin-inbound-call-new.component';
import { CaptureAdminPrescriberIncorrectInformationRequestsComponent } from './capture-admin/pages/capture-admin-prescriber-incorrect-information-requests/capture-admin-prescriber-incorrect-information-requests.component';
import { CaptureAdminNameMappingsComponent } from './capture-admin/pages/capture-admin-name-mappings/capture-admin-name-mappings.component';
import { CaptureAdminClientChoresComponent } from './capture-admin/pages/capture-admin-client-chores/capture-admin-client-chores.component';
import { CaptureAdminClientChoreCreateComponent } from './capture-admin/pages/capture-admin-client-chore-create/capture-admin-client-chore-create.component';
import { CaptureAdminImportCuratedSpecialtiesComponent } from './capture-admin/pages/capture-admin-import-curated-specialties/capture-admin-import-curated-specialties.component';
import { CaptureAdminImportCuratedSpecialtiesHistoryComponent } from './capture-admin/pages/capture-admin-import-curated-specialties-history/capture-admin-import-curated-specialties-history.component';
import { ClientPortalConsultNoteViewerComponent } from './client-portal/pages/client-portal-consult-note-viewer/client-portal-consult-note-viewer.component';
import { ConsultNotesComponent } from './consult-notes/pages/consult-notes/consult-notes.component';
import { ConsultNoteComponent } from './consult-notes/pages/consult-note/consult-note.component';
import { IccCapturesComponent } from './icc/pages/icc-captures/icc-captures.component';
import { IccCaptureDetailComponent } from './icc/pages/icc-capture-detail/icc-capture-detail.component';
import { IccImportEligibleProvidersComponent } from './icc/pages/icc-import-eligible-providers/icc-import-eligible-providers.component';
import { IccImportEligibleProvidersHistoryComponent } from './icc/pages/icc-import-eligible-providers-history/icc-import-eligible-providers-history.component';
import { IccImportMedicaidCodesComponent } from './icc/pages/icc-import-medicaid-codes/icc-import-medicaid-codes.component';
import { IccImportMedicaidCodesHistoryComponent } from './icc/pages/icc-import-medicaid-codes-history/icc-import-medicaid-codes-history.component';
import { IccImportIneligibleDrugsComponent } from './icc/pages/icc-import-ineligible-drugs/icc-import-ineligible-drugs.component';
import { IccImportIneligibleDrugsHistoryComponent } from './icc/pages/icc-import-ineligible-drugs-history/icc-import-ineligible-drugs-history.component';
import { IccImportCatalogedDrugsComponent } from './icc/pages/icc-import-cataloged-drugs/icc-import-cataloged-drugs.component';
import { IccImportCatalogedDrugsHistoryComponent } from './icc/pages/icc-import-cataloged-drugs-history/icc-import-cataloged-drugs-history.component';
import { IccImportOrphanExemptionDrugsComponent } from './icc/pages/icc-import-orphan-exemption-drugs/icc-import-orphan-exemption-drugs.component';
import { IccImportOrphanExemptionDrugsHistoryComponent } from './icc/pages/icc-import-orphan-exemption-drugs-history/icc-import-orphan-exemption-drugs-history.component';
import { IccImportPharmaciesComponent } from './icc/pages/icc-import-pharmacies/icc-import-pharmacies.component';
import { IccImportPharmaciesHistoryComponent } from './icc/pages/icc-import-pharmacies-history/icc-import-pharmacies-history.component';
import { IccImportClaimsComponent } from './icc/pages/icc-import-claims/icc-import-claims.component';
import { IccImportClaimsHistoryComponent } from './icc/pages/icc-import-claims-history/icc-import-claims-history.component';
import { IccImportCaptureEligibilityFindingsComponent } from './icc/pages/icc-import-capture-eligibility-findings/icc-import-capture-eligibility-findings.component';
import { IccImportCaptureEligibilityFindingsHistoryComponent } from './icc/pages/icc-import-capture-eligibility-findings-history/icc-import-capture-eligibility-findings-history.component';
import { IccExportClaimsComponent } from './icc/pages/icc-export-claims/icc-export-claims.component';
import { ConsultNoteHistoryComponent } from './consult-notes/pages/consult-note-history/consult-note-history.component';
import { ConsultNotePrescriptionsComponent } from './consult-notes/pages/consult-note-prescriptions/consult-note-prescriptions.component';
import { ConsultNoteRelatedComponent } from './consult-notes/pages/consult-note-related/consult-note-related.component';
import { CaptureAdminCaptureSearchComponent } from './capture-admin/pages/capture-admin-capture-search/capture-admin-capture-search.component';
import { CaptureAdminPatientInteractionLocationsComponent } from './capture-admin/pages/capture-admin-patient-interaction-locations/capture-admin-patient-interaction-locations.component';
import { CaptureAdminPatientInteractionLocationReportsComponent } from './capture-admin/pages/capture-admin-patient-interaction-location-reports/capture-admin-patient-interaction-location-reports.component';

import { ClientPortalClientPrescriberTaskReportsComponent } from './client-portal/pages/client-portal-client-prescriber-task-reports/client-portal-client-prescriber-task-reports.component';
import { ClientPortalClientReferralTaskReportsComponent } from './client-portal/pages/client-portal-client-referral-task-reports/client-portal-client-referral-task-reports.component';
import { ClientPortalClientConsultNoteTaskReportsComponent } from './client-portal/pages/client-portal-client-consult-note-task-reports/client-portal-client-consult-note-task-reports.component';
import { ClientPortalProgramOverviewComponent } from './client-portal/pages/client-portal-program-overview/client-portal-program-overview.component';
import { CaptureAdminPatientsComponent } from './capture-admin/pages/capture-admin-patients/capture-admin-patients.component';
import { CaptureAdminPatientComponent } from './capture-admin/pages/capture-admin-patient/capture-admin-patient.component';
import { CaptureAdminProvidersComponent } from './capture-admin/pages/capture-admin-providers/capture-admin-providers.component';
import { CaptureAdminProviderComponent } from './capture-admin/pages/capture-admin-provider/capture-admin-provider.component';
import { CaptureAdminProviderNewComponent } from './capture-admin/pages/capture-admin-provider-new/capture-admin-provider-new.component';
import { CaptureAdminProvidersMechanicalTurkQueueComponent } from './capture-admin/pages/capture-admin-providers-mechanical-turk-queue/capture-admin-providers-mechanical-turk-queue.component';
import { CaptureAdminProviderOfficeStatusReviewQueueComponent } from './capture-admin/pages/capture-admin-provider-office-status-review-queue/capture-admin-provider-office-status-review-queue.component';
import { ClientReferralTaskHistoryComponent } from './client-tasks/pages/client-referral-task-history/client-referral-task-history.component';
import { ClientConsultNoteTaskHistoryComponent } from './client-tasks/pages/client-consult-note-task-history/client-consult-note-task-history.component';
import { ClientPrescriberTaskHistoryComponent } from './client-tasks/pages/client-prescriber-task-history/client-prescriber-task-history.component';
import { CaptureAdminMyInboundFaxesComponent } from './capture-admin/pages/capture-admin-my-inbound-faxes/capture-admin-my-inbound-faxes.component';
import { CaptureAdminMyConsultNotesComponent } from './capture-admin/pages/capture-admin-my-consult-notes/capture-admin-my-consult-notes.component';
import { CaptureAdminMyClientChoresComponent } from './capture-admin/pages/capture-admin-my-client-chores/capture-admin-my-client-chores.component';
import { CaptureAdminOfficesComponent } from './capture-admin/pages/capture-admin-offices/capture-admin-offices.component';
import { CaptureAdminOfficeComponent } from './capture-admin/pages/capture-admin-office/capture-admin-office.component';
import { CaptureAdminOfficeStatusReviewQueueComponent } from './capture-admin/pages/capture-admin-office-status-review-queue/capture-admin-office-status-review-queue.component';
import { CaptureAdminOfficeNewComponent } from './capture-admin/pages/capture-admin-office-new/capture-admin-office-new.component';
import { LlmPlaygroundComponent } from './llm-playground/llm-playground.component';
import { LlmPlaygroundProjectsComponent } from './llm-playground/pages/llm-playground-projects/llm-playground-projects.component';
import { LlmPlaygroundProjectComponent } from './llm-playground/pages/llm-playground-project/llm-playground-project.component';
import { LlmPlaygroundBatchComponent } from './llm-playground/pages/llm-playground-batch/llm-playground-batch.component';

import { ClientPortalClientDeactivatedTaskComponent } from './client-portal/pages/client-portal-client-deactivated-task/client-portal-client-deactivated-task.component';
import { CaptureAdminOfficeAuditComponent } from './capture-admin/pages/capture-admin-office-audit/capture-admin-office-audit.component';
import { CaptureAdminProviderAuditComponent } from './capture-admin/pages/capture-admin-provider-audit/capture-admin-provider-audit.component';

import { CaptureAdminPatientCommunicationsComponent } from './capture-admin/pages/capture-admin-patient-communications/capture-admin-patient-communications.component';
import { CaptureAdminPatientCapturesComponent } from './capture-admin/pages/capture-admin-patient-captures/capture-admin-patient-captures.component';
import { CaptureAdminPatientReferralsComponent } from './capture-admin/pages/capture-admin-patient-referrals/capture-admin-patient-referrals.component';
import { CaptureAdminPatientEncountersComponent } from './capture-admin/pages/capture-admin-patient-encounters/capture-admin-patient-encounters.component';
import { CaptureAdminPatientPrescriptionsComponent } from './capture-admin/pages/capture-admin-patient-prescriptions/capture-admin-patient-prescriptions.component';
import { CaptureAdminPatientDocumentsComponent } from './capture-admin/pages/capture-admin-patient-documents/capture-admin-patient-documents.component';
import { CaptureAdminProviderCommunicationsComponent } from './capture-admin/pages/capture-admin-provider-communications/capture-admin-provider-communications.component';
import { CaptureAdminProviderPrescriptionsComponent } from './capture-admin/pages/capture-admin-provider-prescriptions/capture-admin-provider-prescriptions.component';
import { CaptureAdminProviderEncountersComponent } from './capture-admin/pages/capture-admin-provider-encounters/capture-admin-provider-encounters.component';
import { CaptureAdminProviderCapturesComponent } from './capture-admin/pages/capture-admin-provider-captures/capture-admin-provider-captures.component';

import { CaptureAdminInternalConsultantCapturesComponent } from './capture-admin/pages/capture-admin-internal-consultant-captures/capture-admin-internal-consultant-captures.component';

const authGuard = (route: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(route);
const accessManageUsersGuard = () => inject(AccessManageUsersGuard).canActivate();
const accessManageClientsGuard = () => inject(AccessManageClientsGuard).canActivate();
const accessManageClientChoresGuard = () => inject(AccessManageClientChoresGuard).canActivate();
const accessReferralClaimsGuard = () => inject(AccessReferralClaimsGuard).canActivate();
const accessIccClaimsGuard = () => inject(AccessIccClaimsGuard).canActivate();
const deactivatedClientCePortalTasksGuard = () => inject(DeactivatedClientCePortalTasksGuard).canActivate();

const routes: Routes = [
  {
    path: 'capture-admin',
    component: CaptureAdminComponent,
    children: [
      {
        path: 'change-log/:type/:id',
        component: CaptureAdminAuditComponent,
        canActivate: [authGuard]
      },
      {
        path: 'captures',
        component: CaptureAdminCaptureQueueComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'capture-search',
        component: CaptureAdminCaptureSearchComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'captures/:id',
        component: CaptureDetailComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'captures/:id/history',
        component: CaptureHistoryComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'captures/:id/history-new',
        component: CaptureHistoryNewComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'captures/:id/fax',
        component: CaptureFaxComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'consult-notes',
        component: ConsultNotesComponent,
        data: { feature: 'consultNotePages' },
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'consult-notes/:id',
        component: ConsultNoteComponent,
        data: { feature: 'consultNotePages' },
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'consult-notes/:id/history',
        component: ConsultNoteHistoryComponent,
        data: { feature: 'consultNotePages' },
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'consult-notes/:id/prescriptions',
        component: ConsultNotePrescriptionsComponent,
        data: { feature: 'consultNotePages' },
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'consult-notes/:id/related',
        component: ConsultNoteRelatedComponent,
        data: { feature: 'consultNotePages' },
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'reports/capture-reports',
        component: CaptureAdminCaptureReportsComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'reports/capture-invoice-line-item-reports',
        component: CaptureAdminCaptureInvoiceLineItemReportsComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'reports/capture-billing-state-reports',
        component: CaptureAdminCaptureBillingStateReportsComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'reports/partner-portal-claim-reports',
        component: CaptureAdminPartnerPortalClaimReportComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'reports/specialty-store-claim-patient-match-reports',
        component: CaptureAdminSpecialtyStoreClaimPatientMatchReportsComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'reports/user-timing-reports',
        component: CaptureAdminUserTimingReportsComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'reports/missing-ndc-reports',
        component: CaptureAdminMissingNdcReportsComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'reports/patient-interaction-location-reports',
        component: CaptureAdminPatientInteractionLocationReportsComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'communications/faxable-patient-prescriber-reports',
        component: CaptureAdminFaxablePatientPrescriberReportsComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'communications/inbound-faxes',
        component: CaptureAdminInboundFaxesComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'communications/inbound-faxes/upload',
        component: CaptureAdminFaxUploadComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'communications/inbound-faxes/:id',
        component: CaptureAdminInboundFaxComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'communications/import-outbound-faxes',
        component: CaptureAdminImportOutboundFaxesComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'communications/import-outbound-faxes/history',
        component: CaptureAdminImportOutboundFaxesHistoryComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'tools/import-claims',
        component: CaptureAdminImportClaimsComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'tools/import-claims/history',
        component: CaptureAdminImportClaimsHistoryComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'communications/inbound-calls',
        component: CaptureAdminInboundCallsComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'communications/inbound-calls/new',
        component: CaptureAdminInboundCallNewComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'communications/inbound-calls/:id',
        component: CaptureAdminInboundCallComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'referral-claims/clients/client-prescriber-tasks',
        component: CaptureAdminClientPrescriberTasksComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'referral-claims/clients/client-prescriber-tasks/:id',
        component: ClientPrescriberTaskComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'referral-claims/clients/client-prescriber-tasks/:id/history-items',
        component: ClientPrescriberTaskHistoryComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'referral-claims/clients/client-consult-note-tasks',
        component: CaptureAdminClientConsultNoteTasksComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'referral-claims/clients/client-consult-note-tasks/:id',
        component: ClientConsultNoteTaskComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'referral-claims/clients/client-consult-note-tasks/:id/history-items',
        component: ClientConsultNoteTaskHistoryComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'referral-claims/clients/client-referral-tasks',
        component: CaptureAdminClientReferralTasksComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'referral-claims/clients/client-referral-tasks/:id',
        component: ClientReferralTaskComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'referral-claims/clients/client-referral-tasks/:id/history-items',
        component: ClientReferralTaskHistoryComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'referral-claims/clients/consult-notes',
        component: CaptureAdminClientConsultNotesComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'referral-claims/clients/direct-consult-notes',
        component: CaptureAdminClientDirectConsultNotesComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'tools/file-check',
        component: CaptureAdminFileCheckComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'tools/file-check/results',
        component: CaptureAdminFileCheckResultsComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'tools/client-files',
        component: CaptureAdminClientFilesComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'tools/partner-files',
        component: CaptureAdminPartnerFilesComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'tools/referral-prescriber-specialty-mapping',
        component: CaptureAdminImportReferralPrescriberSpecialtyMappingComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'tools/referral-prescriber-specialty-mapping/history',
        component: CaptureAdminImportReferralPrescriberSpecialtyMappingHistoryComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'tools/curated-specialties',
        component: CaptureAdminImportCuratedSpecialtiesComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'tools/curated-specialties/history',
        component: CaptureAdminImportCuratedSpecialtiesHistoryComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'tools/import-capture-invoice-line-items',
        component: CaptureAdminImportCaptureInvoiceLineItemsComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'tools/import-capture-invoice-line-items/history',
        component: CaptureAdminImportCaptureInvoiceLineItemsHistoryComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'tools/import-patient-interaction-locations',
        component: CaptureAdminImportPatientInteractionLocationsComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'tools/import-patient-interaction-locations/history',
        component: CaptureAdminImportPatientInteractionLocationsHistoryComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'tools/prescriber-incorrect-information-requests',
        component: CaptureAdminPrescriberIncorrectInformationRequestsComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'tools/patient-match-approvals',
        component: CaptureAdminNameMappingsComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'tools/client-chores',
        component: CaptureAdminClientChoresComponent,
        canActivate: [authGuard, accessManageClientChoresGuard],
      },
      {
        path: 'tools/client-chore/create',
        component: CaptureAdminClientChoreCreateComponent,
        canActivate: [authGuard, accessManageClientChoresGuard],
      },
      {
        path: 'client-management/clients',
        component: CaptureAdminClientsComponent,
        canActivate: [authGuard],
      },
      {
        path: 'client-management/clients/new',
        component: CaptureAdminClientNewComponent,
        canActivate: [authGuard, accessManageClientsGuard],
      },
      {
        path: 'client-management/clients/:id',
        component: CaptureAdminClientComponent,
        canActivate: [authGuard],
      },
      {
        path: 'client-management/clients/:id/edit',
        component: CaptureAdminClientEditComponent,
        canActivate: [authGuard, accessManageClientsGuard],
      },
      {
        path: 'client-management/clients/:id/change-log',
        component: CaptureAdminClientAuditComponent,
        canActivate: [authGuard],
      },
      {
        path: 'client-management/clients/:id/edit-configuration-options',
        component: CaptureAdminClientEditConfigurationComponent,
        canActivate: [authGuard, accessManageClientsGuard],
      },
      {
        path: 'client-management/clients/:id/options-change-log',
        component: CaptureAdminClientOptionsAuditComponent,
        canActivate: [authGuard]
      },
      {
        path: 'client-management/patient-interaction-locations',
        component: CaptureAdminPatientInteractionLocationsComponent,
        canActivate: [authGuard, accessManageClientsGuard],
      },
      {
        path: 'users',
        component: CaptureAdminUsersComponent,
        canActivate: [authGuard, accessManageUsersGuard],
      },
      {
        path: 'users/new',
        component: CaptureAdminUserNewComponent,
        canActivate: [authGuard, accessManageUsersGuard],
      },
      {
        path: 'users/:id/edit',
        component: CaptureAdminUserEditComponent,
        canActivate: [authGuard, accessManageUsersGuard],
      },
      {
        path: 'users/:id/change-log',
        component: CaptureAdminUserAuditComponent,
        canActivate: [authGuard, accessManageUsersGuard],
      },
      {
        path: 'users/:id',
        component: CaptureAdminUserComponent,
        canActivate: [authGuard, accessManageUsersGuard],
      },
      {
        path: 'icc-claims/captures',
        component: IccCapturesComponent,
        canActivate: [authGuard, accessIccClaimsGuard],
      },
      {
        path: 'icc-claims/captures/:id',
        component: IccCaptureDetailComponent,
        canActivate: [authGuard, accessIccClaimsGuard],
      },
      {
        path: 'icc-claims/imports/eligible-providers',
        component: IccImportEligibleProvidersComponent,
        canActivate: [authGuard, accessIccClaimsGuard],
      },
      {
        path: 'icc-claims/imports/eligible-providers/history',
        component: IccImportEligibleProvidersHistoryComponent,
        canActivate: [authGuard, accessIccClaimsGuard],
      },
      {
        path: 'icc-claims/imports/medicaid-codes',
        component: IccImportMedicaidCodesComponent,
        canActivate: [authGuard, accessIccClaimsGuard],
      },
      {
        path: 'icc-claims/imports/medicaid-codes/history',
        component: IccImportMedicaidCodesHistoryComponent,
        canActivate: [authGuard, accessIccClaimsGuard],
      },
      {
        path: 'icc-claims/imports/ineligible-drugs',
        component: IccImportIneligibleDrugsComponent,
        canActivate: [authGuard, accessIccClaimsGuard],
      },
      {
        path: 'icc-claims/imports/ineligible-drugs/history',
        component: IccImportIneligibleDrugsHistoryComponent,
        canActivate: [authGuard, accessIccClaimsGuard],
      },
      {
        path: 'icc-claims/imports/cataloged-drugs',
        component: IccImportCatalogedDrugsComponent,
        canActivate: [authGuard, accessIccClaimsGuard],
      },
      {
        path: 'icc-claims/imports/cataloged-drugs/history',
        component: IccImportCatalogedDrugsHistoryComponent,
        canActivate: [authGuard, accessIccClaimsGuard],
      },
      {
        path: 'icc-claims/imports/orphan-exemption-drugs',
        component: IccImportOrphanExemptionDrugsComponent,
        canActivate: [authGuard, accessIccClaimsGuard],
      },
      {
        path: 'icc-claims/imports/orphan-exemption-drugs/history',
        component: IccImportOrphanExemptionDrugsHistoryComponent,
        canActivate: [authGuard, accessIccClaimsGuard],
      },
      {
        path: 'icc-claims/imports/pharmacies',
        component: IccImportPharmaciesComponent,
        canActivate: [authGuard, accessIccClaimsGuard],
      },
      {
        path: 'icc-claims/imports/pharmacies/history',
        component: IccImportPharmaciesHistoryComponent,
        canActivate: [authGuard, accessIccClaimsGuard],
      },
      {
        path: 'icc-claims/imports/claims',
        component: IccImportClaimsComponent,
        canActivate: [authGuard, accessIccClaimsGuard],
      },
      {
        path: 'icc-claims/imports/claims/history',
        component: IccImportClaimsHistoryComponent,
        canActivate: [authGuard, accessIccClaimsGuard],
      },
      {
        path: 'icc-claims/imports/capture-eligibility-findings',
        component: IccImportCaptureEligibilityFindingsComponent,
        canActivate: [authGuard, accessIccClaimsGuard],
      },
      {
        path: 'icc-claims/imports/capture-eligibility-findings/history',
        component: IccImportCaptureEligibilityFindingsHistoryComponent,
        canActivate: [authGuard, accessIccClaimsGuard],
      },
      {
        path: 'icc-claims/exports/claims',
        component: IccExportClaimsComponent,
        canActivate: [authGuard, accessIccClaimsGuard],
      },
      {
        path: 'patients',
        component: CaptureAdminPatientsComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'patients/:id',
        component: CaptureAdminPatientComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'patients/:id/communications',
        component: CaptureAdminPatientCommunicationsComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'patients/:id/captures',
        component: CaptureAdminPatientCapturesComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'patients/:id/referrals',
        component: CaptureAdminPatientReferralsComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'patients/:id/encounters',
        component: CaptureAdminPatientEncountersComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'patients/:id/prescriptions',
        component: CaptureAdminPatientPrescriptionsComponent,
      },
      {
        path: 'patients/:id/documents',
        component: CaptureAdminPatientDocumentsComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'providers/mechanical-turk-reviews',
        component: CaptureAdminProvidersMechanicalTurkQueueComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'providers/all',
        component: CaptureAdminProvidersComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'providers/new',
        component: CaptureAdminProviderNewComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'providers/:npi',
        component: CaptureAdminProviderComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'providers/:npi/audits',
        component: CaptureAdminProviderAuditComponent,
        canActivate: [authGuard]
      },
      {
        path: 'providers/:npi/communications',
        component: CaptureAdminProviderCommunicationsComponent,
        canActivate: [authGuard]
      },
      {
        path: 'providers/:npi/prescriptions',
        component: CaptureAdminProviderPrescriptionsComponent,
        canActivate: [authGuard]
      },
      {
        path: 'providers/:npi/encounters',
        component: CaptureAdminProviderEncountersComponent,
        canActivate: [authGuard]
      },
      {
        path: 'providers/:npi/captures',
        component: CaptureAdminProviderCapturesComponent,
        canActivate: [authGuard]
      },
      {
        path: 'providers/provider-offices/status-reviews',
        component: CaptureAdminProviderOfficeStatusReviewQueueComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'offices/all',
        component: CaptureAdminOfficesComponent,
        canActivate: [authGuard]
      },
      {
        path: 'offices/status-reviews',
        component: CaptureAdminOfficeStatusReviewQueueComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'offices/new',
        component: CaptureAdminOfficeNewComponent,
        canActivate: [authGuard]
      },
      {
        path: 'offices/:id',
        component: CaptureAdminOfficeComponent,
        canActivate: [authGuard]
      },
      {
        path: 'offices/:id/audits',
        component: CaptureAdminOfficeAuditComponent,
        canActivate: [authGuard]
      },
      {
        path: 'my-work/faxes',
        component: CaptureAdminMyInboundFaxesComponent,
        canActivate: [authGuard, accessReferralClaimsGuard]
      },
      {
        path: 'my-work/faxes/:id',
        component: CaptureAdminInboundFaxComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
      {
        path: 'my-work/consult-notes',
        component: CaptureAdminMyConsultNotesComponent,
        canActivate: [authGuard, accessReferralClaimsGuard]
      },
      {
        path: 'my-work/client-chores',
        component: CaptureAdminMyClientChoresComponent,
        canActivate: [authGuard, accessReferralClaimsGuard]
      },
      {
        path: 'tools/llm-playground/projects',
        component: LlmPlaygroundProjectsComponent,
        canActivate: [authGuard]
      },
      {
        path: 'tools/llm-playground/projects/new',
        component: LlmPlaygroundProjectsComponent,
        canActivate: [authGuard]
      },
      {
        path: 'tools/llm-playground/projects/:id',
        component: LlmPlaygroundProjectComponent,
        canActivate: [authGuard]
      },
      {
        path: 'tools/llm-playground/batches/:id',
        component: LlmPlaygroundBatchComponent,
        canActivate: [authGuard],
      },
      {
        path: 'internal-consultant-captures',
        component: CaptureAdminInternalConsultantCapturesComponent,
        canActivate: [authGuard, accessReferralClaimsGuard],
      },
    ],
  },
  {
    path: 'ce-portal',
    component: ClientPortalComponent,
    children: [
      {
        path: '',
        redirectTo: '/ce-portal/captures',
        pathMatch: 'full',
      },
      {
        path: 'captures',
        component: ClientPortalSearchComponent,
        canActivate: [authGuard],
      },
      {
        path: 'captures/:id',
        component: CaptureDetailComponent,
        canActivate: [authGuard],
      },
      {
        path: 'consult-note-viewer/:id',
        component: ClientPortalConsultNoteViewerComponent,
        canActivate: [authGuard],
      },
      {
        path: 'icc-captures/:id',
        component: IccCaptureDetailComponent,
        canActivate: [authGuard],
      },
      {
        path: 'tasks/client-prescriber-tasks',
        component: ClientPortalClientPrescriberTasksComponent,
        canActivate: [authGuard, deactivatedClientCePortalTasksGuard],
      },
      {
        path: 'tasks/client-prescriber-tasks/:id',
        component: ClientPrescriberTaskComponent,
        canActivate: [authGuard, deactivatedClientCePortalTasksGuard],
      },
      {
        path: 'tasks/client-consult-note-tasks',
        component: ClientPortalClientConsultNoteTasksComponent,
        canActivate: [authGuard, deactivatedClientCePortalTasksGuard],
      },
      {
        path: 'tasks/client-consult-note-tasks/:id',
        component: ClientConsultNoteTaskComponent,
        canActivate: [authGuard, deactivatedClientCePortalTasksGuard],
      },
      {
        path: 'tasks/client-referral-tasks',
        component: ClientPortalClientReferralTasksComponent,
        canActivate: [authGuard, deactivatedClientCePortalTasksGuard],
      },
      {
        path: 'tasks/client-referral-tasks/:id',
        component: ClientReferralTaskComponent,
        canActivate: [authGuard, deactivatedClientCePortalTasksGuard],
      },
      {
        path: 'tasks/deactivated',
        component: ClientPortalClientDeactivatedTaskComponent,
        canActivate: [authGuard],
      },
      {
        path: 'reports/captured-claim-reports',
        component: ClientPortalMatchReportsComponent,
        canActivate: [authGuard],
      },
      {
        path: 'reports/verified-prescription-reports',
        component: ClientPortalPrescriptionReportComponent,
        canActivate: [authGuard],
      },
      {
        path: 'reports/capture-invoice-line-item-reports',
        component: ClientPortalCaptureInvoiceLineItemReportComponent,
        canActivate: [authGuard],
      },
      {
        path: 'reports/client-prescriber-task-reports',
        component: ClientPortalClientPrescriberTaskReportsComponent,
        canActivate: [authGuard],
      },
      {
        path: 'reports/client-referral-task-reports',
        component: ClientPortalClientReferralTaskReportsComponent,
        canActivate: [authGuard],
      },
      {
        path: 'reports/client-consult-note-task-reports',
        component: ClientPortalClientConsultNoteTaskReportsComponent,
        canActivate: [authGuard],
      },
      {
        path: 'consult-notes/manual',
        component: ClientPortalConsultNotesComponent,
        canActivate: [authGuard],
      },
      {
        path: 'consult-notes/direct',
        component: ClientPortalDirectConsultNotesComponent,
        canActivate: [authGuard],
      },
      {
        path: 'preferences',
        component: ClientPortalPreferencesComponent,
        canActivate: [authGuard],
      },
      {
        path: 'program-overview',
        component: ClientPortalProgramOverviewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'locations',
        component: ClientPortalPatientInteractionLocationsComponent,
        canActivate: [authGuard],
      },
    ],
  },
  {
    path: 'partner-portal',
    component: PartnerPortalComponent,
    children: [
      {
        path: '',
        redirectTo: '/partner-portal/captures',
        pathMatch: 'full',
      },
      {
        path: 'captures',
        component: PartnerPortalSearchComponent,
        canActivate: [authGuard],
      },
      {
        path: 'captures/:id',
        component: CaptureDetailComponent,
        canActivate: [authGuard],
      },
      {
        path: 'match-reports',
        component: PartnerPortalMatchReportsComponent,
        canActivate: [authGuard],
      },
    ],
  },
  {
    path: 'llm-playground',
    component: LlmPlaygroundComponent,
    children: [
      {
        path: '',
        redirectTo: '/llm-playground/projects',
        pathMatch: 'full',
      },
      {
        path: 'projects',
        component: LlmPlaygroundProjectsComponent,
        canActivate: [authGuard]
      },
      {
        path: 'projects/new',
        component: LlmPlaygroundProjectsComponent,
        canActivate: [authGuard]
      },
      {
        path: 'projects/:id',
        component: LlmPlaygroundProjectComponent,
        canActivate: [authGuard]
      },
      {
        path: 'batches/:id',
        component: LlmPlaygroundBatchComponent,
        canActivate: [authGuard],
      },
    ]
  },
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent,
    canActivate: [authGuard],
  },
  { path: '404', component: NotFoundComponent },
  { path: 'maintenance', component: MaintenanceComponent },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      enableTracing: environment.debugRouter
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
