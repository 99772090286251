import { CaptureStatusReason } from '../enums/capture-status-reason.enum';
import { keyBy } from 'lodash-es';

export class CaptureTransitionReasonOption {
  constructor(public value: string, public display: string, public selectable: boolean = true) { }
}

const reasonOptions = [
  new CaptureTransitionReasonOption(
    CaptureStatusReason.additionalReferralDocumentationRequired,
    'Requires additional referral documentation (e.g. office, provider)'
  ),
  new CaptureTransitionReasonOption(
    CaptureStatusReason.referralCollectionDeferred,
    'Referral Collection Deferred'
  ),
  new CaptureTransitionReasonOption(
    CaptureStatusReason.drugNotReferencedOnConsultNote,
    'Drug not referenced on consult note'
  ),
  new CaptureTransitionReasonOption(
    CaptureStatusReason.invalidPatientFirstNameMatch,
    'Invalid Patient First Name Match (i.e. Patient on claim and CE record do not match)'
  ),
  new CaptureTransitionReasonOption(
    CaptureStatusReason.invalidPatientLastNameMatch,
    'Invalid Patient Last Name Match (i.e. Patient on claim and CE record do not match)'
  ),
  new CaptureTransitionReasonOption(CaptureStatusReason.notCePatient, 'Not a patient of the CE'),
  new CaptureTransitionReasonOption(
    CaptureStatusReason.serviceDateBeforeFirstInteraction,
    'Service date before first patient interaction'
  ),
  new CaptureTransitionReasonOption(CaptureStatusReason.expired, 'Expired'),
  new CaptureTransitionReasonOption(CaptureStatusReason.espExpired, 'ESP Expired'),
  new CaptureTransitionReasonOption(CaptureStatusReason.psychologyProviderDrug, 'Psychology Provider/Drug'),
  new CaptureTransitionReasonOption(CaptureStatusReason.roiNotObtained, 'ROI Not Obtained'),
  new CaptureTransitionReasonOption(CaptureStatusReason.providerNotValid, 'Provider Not Valid'),
  new CaptureTransitionReasonOption(CaptureStatusReason.missingInfo, 'Missing info', false),
  new CaptureTransitionReasonOption(CaptureStatusReason.prescriberIsCeProvider, 'Prescriber is a client provider'),
  new CaptureTransitionReasonOption(
    CaptureStatusReason.rxServiceDateOutsideEligibilityWindow,
    'Eligibility window: Documentation gathered out of rx service date range'
  ),
  new CaptureTransitionReasonOption(CaptureStatusReason.consultNoteInCeEhr, 'Consult note in client EHR'),
  new CaptureTransitionReasonOption(
    CaptureStatusReason.emrConsultNoteReviewRequired,
    'Request client review of consult note in EMR'
  ),
  new CaptureTransitionReasonOption(
    CaptureStatusReason.referralNotBeforeDrugWrittenDate,
    'Referral is not before drug written date'
  ),
  new CaptureTransitionReasonOption(
    CaptureStatusReason.prescriberSpecialtyDoesNotReceiveReferrals,
    'Prescriber specialty does not receive referrals'
  ),
  new CaptureTransitionReasonOption(CaptureStatusReason.patientNotAtPractice, 'Patient not at this practice'),
  new CaptureTransitionReasonOption(CaptureStatusReason.prescriberNotAtPractice, 'Provider not at this practice'),
  new CaptureTransitionReasonOption(
    CaptureStatusReason.practiceRequiresPatientRelease,
    'Practice requires patient release'
  ),
  new CaptureTransitionReasonOption(CaptureStatusReason.noRecordForDateOfService, 'No record for date of service'),
  new CaptureTransitionReasonOption(CaptureStatusReason.additionalInformationRequested, 'Additional information requested'),
  new CaptureTransitionReasonOption(CaptureStatusReason.other, 'Other'),
  new CaptureTransitionReasonOption(CaptureStatusReason.drugValueIsTooLow, 'Drug Value is too low'),
  new CaptureTransitionReasonOption(CaptureStatusReason.requiresReviewDrug, 'Requires Review: Drug'),
  new CaptureTransitionReasonOption(
    CaptureStatusReason.notAValidPrescriberForClient,
    'Not a valid prescriber for client'
  ),
  new CaptureTransitionReasonOption(
    CaptureStatusReason.par8oReversal,
    'R1 reversed this capture', false),
  new CaptureTransitionReasonOption(
    CaptureStatusReason.claimPartnerReversal,
    'The claim partner reversed this capture', false),
  new CaptureTransitionReasonOption(
    CaptureStatusReason.claimPartnerRejection,
    'The claim partner rejected this capture'),
  new CaptureTransitionReasonOption(
    CaptureStatusReason.claimPartnerRetraction,
    'The claim partner retracted this capture', false),
  new CaptureTransitionReasonOption(
    CaptureStatusReason.claimPartnerIneligible,
    'The claim partner has made this capture ineligible', false),
  new CaptureTransitionReasonOption( CaptureStatusReason.noValidEncounters, 'No Valid Encounters'),
  new CaptureTransitionReasonOption(CaptureStatusReason.transmittedNoResponse, 'Transmitted - No Response'),
  new CaptureTransitionReasonOption(
    CaptureStatusReason.transmittedNoResponseToCustomNoteFax,
    'Transmitted - No Response Template 4'
  ),
  new CaptureTransitionReasonOption(CaptureStatusReason.faxFailed, 'Fax Failed'),
  new CaptureTransitionReasonOption(
    CaptureStatusReason.clientRejectedPatientPrescriber,
    'Client Rejected Patient Prescriber'
  ),
  new CaptureTransitionReasonOption(CaptureStatusReason.clientRejectedPrescription, 'Client Rejected Prescription'),
  new CaptureTransitionReasonOption(CaptureStatusReason.clientRejectedPrescriber, 'Client Rejected Prescriber'),
  new CaptureTransitionReasonOption(CaptureStatusReason.clientRejectedPatient, 'Client Rejected Patient'),
  new CaptureTransitionReasonOption(
    CaptureStatusReason.clientRejectedPrescriberPrescription,
    'Not a Valid CE Prescription'
  ),
  new CaptureTransitionReasonOption(CaptureStatusReason.noValidReferral, 'No Valid Referral'),
  new CaptureTransitionReasonOption(CaptureStatusReason.ineligibleManufacturer, 'Ineligible Manufacturer'),
  new CaptureTransitionReasonOption(
    CaptureStatusReason.ineligiblePrescriberSpecialty,
    'Ineligible Prescriber Specialty'
  ),
  new CaptureTransitionReasonOption(
    CaptureStatusReason.incorrectAnswersInCeTaskIdentified,
    'Incorrect Answers in CE Task Identified'
  ),
  new CaptureTransitionReasonOption(CaptureStatusReason.standardFaxOut, 'Standard Fax Out'),
  new CaptureTransitionReasonOption(CaptureStatusReason.faxQueued, 'Fax Queued'),
  new CaptureTransitionReasonOption(
    CaptureStatusReason.clientRequestedConsultNoteCollection,
    'Client requests R1 get consult note',
    false
  ),
  new CaptureTransitionReasonOption(CaptureStatusReason.requestOlderConsultNote, 'Request older consult note'),
  new CaptureTransitionReasonOption(
    CaptureStatusReason.drugNotReferencedRequestOlderConsultNote,
    'Drug not referenced - request older consult note'
  ),
  new CaptureTransitionReasonOption(
    CaptureStatusReason.requestMoreRecentConsultNote,
    'Request more recent consult note'
  ),
  new CaptureTransitionReasonOption(
    CaptureStatusReason.drugNotReferencedRequestMoreRecentConsultNote,
    'Drug not referenced - request more recent consult note'
  ),
  new CaptureTransitionReasonOption(
    CaptureStatusReason.faxReceivedHasAnIssue,
    'Fax received has an issue (unreadable etc.)'
  ),
  new CaptureTransitionReasonOption(CaptureStatusReason.needsPayment, 'Needs payment'),
  new CaptureTransitionReasonOption(CaptureStatusReason.needsMailingAddress, 'Needs mailing address'),
  new CaptureTransitionReasonOption(CaptureStatusReason.ceHasConsultNote, 'CE has consult note'),
  new CaptureTransitionReasonOption(CaptureStatusReason.drugNotReferenced, 'Drug not referenced'),
  new CaptureTransitionReasonOption(CaptureStatusReason.practiceWorkingOnRequest, 'Practice is working on request'),
  new CaptureTransitionReasonOption(CaptureStatusReason.patientMarkedInactive, 'Patient marked as inactive'),
  new CaptureTransitionReasonOption(CaptureStatusReason.questionOnPatientMatch, 'Question on patient match'),
  new CaptureTransitionReasonOption(CaptureStatusReason.questionOnReferralMatch, 'Question on referral match'),
  new CaptureTransitionReasonOption(CaptureStatusReason.questionOnConsultNote, 'Question on consult note'),
  new CaptureTransitionReasonOption(
    CaptureStatusReason.alreadyRequestedCEConsultNoteReview,
    'Already requested CE consult note review'
  ),
  new CaptureTransitionReasonOption(CaptureStatusReason.potentialDataFeedIssue, 'Potential data feed issue'),
  new CaptureTransitionReasonOption(CaptureStatusReason.needsManualReview, 'Needs Manual Review'),
  new CaptureTransitionReasonOption(CaptureStatusReason.duplicateClaimVerified, 'Duplicate Claim Verified'),
  new CaptureTransitionReasonOption(
    CaptureStatusReason.notAValidCePrescriptionInternalConsultant,
    'Not a Valid CE Prescription (Internal Consultant)'
  ),
];

const keyedReasonOptions = keyBy(reasonOptions, 'value');

const displayCaptureStatusReason = (reason: CaptureStatusReason): string => {
  const reasonOption = keyedReasonOptions[reason];

  if (reasonOption) {
    return reasonOption.display;
  } else {
    return reason;
  }
}

export {
  keyedReasonOptions,
  reasonOptions,
  displayCaptureStatusReason
};
